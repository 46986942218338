<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <table class="table table-striped table-hover table-responsive">
      <thead>
        <tr>
          <th></th>
          <th v-for="(period, index) in periods" :key="index">{{ period.from }} to {{ period.to }}</th>
        </tr>
      </thead>
      <tbody>
        <tr style="height:50px;" v-for="(monthData, monthKey) in formattedData" :key="monthKey">
          <td style="vertical-align: middle !important;">{{ monthKey }}</td>
          <td
            style="vertical-align: middle !important;"
            v-for="(period, index) in periods"
            :key="index"
          >
            <span v-if="monthData[index]" v-b-tooltip.hover  :title="monthData[index] ? `Users: ${monthData[index].users}, Orders: ${monthData[index].orders}, Purchase: ${monthData[index].purchase}` : ''">
              {{  monthData[index].users + '/' + monthData[index].orders + '/' + monthData[index].purchase }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </card>
</template>
<script>
import { API_LIST } from '@/utils/consts'
import axios from '../../plugins/axios'

export default {
  data() {
    return {
      isLoading: false,
      dateOptions: [
        { value: 'start', text: 'Opening Date' },
        { value: 'close', text: 'Closing Date' },
      ],
    }
  },
  computed: {
    dateOption() {
      return this.dateOptions.find(x => x.value == this.queryParams.date_field)
    },
    items: {
      get() {
        return this.$store.getters['request/userReentryMonthlyReport'] ?? []
      },
      set: v => v,
    },
    periods() {
      return this.items.map(item => ({ from: item.from, to: item.to }))
    },
    formattedData() {
      const data = {}
      this.items.forEach(item => {
        for (const [monthKey, monthValue] of Object.entries(item)) {
          if (monthKey.startsWith('month_')) {
            if (!data[monthKey]) {
              data[monthKey] = []
            }
            data[monthKey].push(monthValue)
          }
        }
      })
      return data
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      axios
        .get(API_LIST.get.userReentryMonthlyReport)
        .then(res => {
          this.items = res?.data?.data || []
          this.$store.dispatch('request/mutateState', { property: 'userReentryMonthlyReport', with: res?.data?.data })
        })
        .finally(() => (this.isLoading = false))
    },
    formatWithCurrency(v) {
      return v ? Number(v).toLocaleString('en-US', { style: 'currency', currency: 'GBP', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'
    },
  },
  mounted() {
    this.getList()
  },
}
</script>
